.countsBody{
    height: 200px;
    color: white;
    display: flex;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    /* border: 1px solid red; */
}

.countsBody div{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(245, 87, 87);
    &:nth-of-type(2){
        background: rgb(242, 242, 95);
        color: black;
    }
    &:nth-of-type(3){
        background: rgb(72, 72, 243);
    }
    &:nth-of-type(4){
        background: rgb(122, 240, 122);
        color: black;
    }
}


.countsBody h1{
    display: flex;
}
@media screen and (max-width: 768px){
    .countsBody p{
        text-align: center;
    }
}