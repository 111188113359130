/* *{
    margin: 0;
    padding:0;
    box-sizing:border-box;
  } */
  
  .loaderBody{
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content:center;
    align-items:center;
    background: #272727;
  }
  
  .Loadercontainer{
    position: relative;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  
  .loaderRing{
    position:absolute;
    height: 200px;
    width: 200px;
    border: 0px solid #272727;
    border-radius:50%;
  }
  
  .loaderRing:nth-child(1){
    border-bottom-width: 10px;
    border-color: #ca2c92;
    animation: rotate1 1.5s linear infinite;
  }
  
  .loaderRing:nth-child(2){
    border-right-width: 10px;
    border-color: #00ffff;
    animation: rotate2 1.5s linear infinite;
  }
  
  .loaderRing:nth-child(3){
    border-top-width: 10px;
    border-color: #E0E722;
    animation: rotate3 1.5s linear infinite;
  }
  
  
  @keyframes rotate1{
    0%{
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)
    }
    100%{
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)
    }
  }
  @keyframes rotate2{
    0%{
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)
    }
    100%{
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)
    }
  }
  @keyframes rotate3{
    0%{
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)
    }
    100%{
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
    }
  }