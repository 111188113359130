.career-page{
}

.CareerBanner{
  position: relative !important;
}

.lets-create {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lets-create img{
  margin-top: -50px;
  padding: 0;
  position: relative;
  z-index: 1;
}
/* .lets-createpage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */
.impact-head {
  color: white;
  height: auto;
}
.CareerBannerText{
  position: absolute !important;
  top: 2.5%;
  width: 100%;
  height: auto;
  z-index: 100;
}

.CareerBannerText h1 {
  font-size: 40px;
  font-weight: 400;
}
.CareerBannerText h6 {
  font-size: 18px;
}

.shapeFuture p{
  text-align: justify;
}
.impact-head button {
  background-color: #2c74d6;
  color: white;
  padding: 1rem 2rem 1rem 2rem;
  border: none;
  border-radius: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.section-2 {
  padding-top: 2rem;
}

.join-us {
  padding: 3rem;
}
.right-part {
  background: #f0f0f0;
  padding: 3rem;
}

.card {
  background: #fff;
  margin: 10px;
  padding: 15px;
  height: 100% !important;
}

.section-3 {
  padding: 2rem;
}

.section-4 {
  padding: 2rem;
}
.in-media {
  background: #07172d;
  width: 100%;
  color: white;
}
.cardStyle {
  border: 1px solid #ddd;
  background-color: #07172d;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.cardParent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 450px){
  .CareerBannerText{
    top: 0%;
  }
  .CareerBannerText h1 {
    font-size: 2.5vh;
  }
}


@media (min-width: 450px) and (max-width: 768px){
  .CareerBannerText{
    top: 1%;
  }
  .CareerBannerText h1 {
    font-size: 2.5vh;
  }
  .shapeFuture{
    /* width: 100%; */
    /* border: 1px solid red; */
  }
}

@media (min-width: 768px){
    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%;
        min-width: 350px !important;
  }
}

@media (min-width: 768px) and (max-width: 1100px){
  .Career-CarouselBody , .carouselBody img{
    height: 350px;
    object-fit: cover;
    /* border: 1px solid red; */
  }
}

@media (min-width: 1200px){
  .cardParent {
    justify-content: space-evenly;
  }
  
}