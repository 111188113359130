.helpYouWinBody {
    height: 300px;
    background: url('../../Images//Services//Service.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; Add position relative to the parent */
  }
  
  .helpYouWinContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
  }

  .helpYouWinContent-btns{
    display: flex;
  }
  /* .verticleLine{
    height: 105px;
    width: 4px;
    background: var(--gradient-optimistic-daydream, linear-gradient(135deg, #009fd4 17.83%, #46b756));
  } */
  
  .helpYouWinContent h1 {
    color: white;
    margin: 0px 0px 20px 0px;
  }
  
  .helpYouWinContent button {
    padding: 7px;
    color: white;
    /* background: transparent; */
    border: 1.5px solid white;
    margin: 10px 10px 0px 10px;
    padding: 15px;
    background: linear-gradient(90deg, transparent 50%, white 50%);
    background-size: 200% 100%;  
    transition: background-position 0.5s ease;
  }
  
  .helpYouWinContent button:hover{
    background-position: 100% 0;
    color: black;
  }

  @media screen and (max-width: 800px) {
    .helpYouWinBody {
      /* background-size: auto; */
    }
    .helpYouWinContent-btns{
      flex-direction: column;
    }

    .helpYouWinContent h1 {
      text-align: center;
    }
  }