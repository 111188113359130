.ServicesBannerBody{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../../Images/Services/ServicesBanner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.ServicesBannerContent{
    text-align: center;
    color: white;
    /* border: 1px solid red; */
}

.ServicesBannerContent h1{
    letter-spacing: 3px;
    margin-bottom: 10px;
    /* border: 1px solid red; */
}

@media screen and (max-width: 768px) {
    .ServicesBannerBody{
        margin-top: -5px;
        background-size: cover;
        height: 350px;
        padding: 5% 2% 5% 2%;
    }

    .ServicesBannerContent{
        padding-top: 20%;
        text-align: center;
        /* height: 300px; */
        /* border: 1px solid red; */
    }

    .ServicesBannerContent h1{
        font-size: 22px;
    }
}
