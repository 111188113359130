.RatingsBandBody{
    height: 200px;
    width: 100%;
    background: #004AAD;
    color: white;
    /* border-top: 1px solid black; */
    position: absolute;
    z-index: 9;
    padding-top: 10px;
    /* margin-top: -10px !important; */
    bottom: 0;
    /* border: 1px solid red; */
    /* display: none; */
}

.Exxon-Call{
    margin-left: 17px;
}

.Exxon-Call a{
    color: #ffffff;
    text-decoration: none;
    margin-left: 10px;
}

.start-exon{
    height: 20px;
}

/* / New Footer / */

.country-logo{
    width: 70px;
    height: 40px; 
    /* border: 1px solid red; */
}

.Exxon-Numberrow{
    margin-bottom: 10px;
}

.ExxonUnderImg{
    height: 30px !important;
    /* border: 1px solid red; */
}

.ExxonUperpart{
    margin-bottom:10px !important;
    width: 80% !important;
}

.ReatingssubscribeDiv{
    display: none;
}

@media screen and (max-width: 768px){
    /* .ratingCard{
        width: 45%;
    } */

    .RatingsBandBody-row1-Parent{
        display: flex;
        justify-content: space-around;
        /* border: 1px solid black; */
    }
    .ReatingssubscribeDiv{
        width: 45%;
        display: block;
        /* border: 1px solid red; */
    }

    .ReatingssubscribeDiv input{
        border-radius: 7px;
        height: 25px;
        width: 130px;
        border: none;
        padding: 3px !important;
        /* font-size: 10px; */
      }
    .ReatingssubscribeDiv button{
        background: #c9ebf6;
        margin: 5px;
        padding: 2px 7px 2px 7px;
        margin-top: 10px;
        border-radius: 8px;
        transition: border-radius 0.5s ease;
        border: none;
        font-size: 12px !important;
      }
/* ---------------------- */

.RatingsBandBody{
    height: 340px !important;
    padding: 0px;
}
.RatingsBandBody-row1{
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.RatingsBandBody-row1 .ratingCard{
    width: 25%;
    /* border: 1px solid red; */
}

.ExxonUnderImg{
    width: 100%;
    margin-left: -5px;
    /* border: 1px solid red; */
}

.RatingsBandBody-row2{
    /* display: none; */
    /* margin: 20px; */
    padding-top: 15px;
    /* height: 40px; */
    display: flex !important;
    justify-content: flex-start;
    /* flex-wrap: wrap !important; */
    /* border: 1px solid red; */
}

.RatingsBandBody-row2 .RatingsBandBody-row2-Card{
    width: 32%;
    /* border: 1px solid red; */
}

.Exxon-Numberrow img{
    height: 40px;
    width: 70px;
    /* border: 1px solid red; */
}

.Exxon-Call{
    margin-top: -18px;
    margin-left: 0px;
    font-size: 10px;
    }
}

@media(min-width:768px) and (max-width:992px){
    .RatingsBandBody{
        height: auto !important;
    }

    .start-exon {
        margin-left: -55px;
        /* border: 1px solid red; */
    }

    .RatingsBandBody-row1{
        display: flex !important;
        flex-direction: row !important;
        /* border: 1px solid red; */
    }

    .RatingsBandBody-row1 .ratingCard{
        width: 25%;
        /* border: 1px solid red; */
    }

    .RatingsBandBody-row2{
        /* display: none; */
        /* margin: 20px; */
        padding-top: 10px;
        /* height: 40px; */
        display: flex !important;
        flex-direction: row !important;
        /* border: 1px solid red; */
    }

    .RatingsBandBody-row2 .RatingsBandBody-row2-Card{
        width: 25%;
        /* border: 1px solid red; */
    }

    .Exxon-Numberrow img{
        height: 40px;
        width: 70px;
        /* border: 1px solid red; */
    }

    .Exxon-Call{
        margin-top: -18px;
    }
}