.brandingBody{
  padding: 0;
}

.brandingFirstRow{
  /* margin-top: -50px; */
  padding: 130px 0px 40px 0px;
  /* background: yellow; */
  background: linear-gradient( rgb(179, 238, 171), white);
  /* border: 1px solid red; */
  color: black;
}



@media screen and (max-width:768px) {

  .main-container{
    display: flex;
    justify-content: center;
    
  }

  .homeService-LefttContainer-Img img{
      transform: translateX(0%) translateY(0%) !important;
  } 
  
  .right-part{
    width: 100%;
  }

  .section-2:nth-of-type(2) .row, .section-2:nth-of-type(4) .row {
    flex-direction: column-reverse !important;
  }
}