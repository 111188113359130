.homeServiceBody{
    /* height: 400px; */
    padding: 0% 5% 5% 5%;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* border: 1px solid red; */
}

.homeServiceBody-row1, .homeServiceBody-row2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3%;
    /* border: 1px solid red; */
}

.homeService-LeftConatiner{
    width: 48%;
}

.homeService-LeftConatiner h2{
    margin-bottom: 5%;
}

.homeService-RightConatiner{
    width: 45%;
    padding: 1%;
}

.services-btn{
    border: 2px solid blue;
    /* margin-left: 10px; */
    padding: 7px;
    background: linear-gradient(90deg, transparent 50%, black 50%);
    background-size: 210% 100%;  
    transition: background-position 0.5s ease;
}

.services-btn:hover{
    border: 2px solid blue;
    color: white;
    background-position: 100% 0;
}

.homeService-RightConatiner-Img, .homeService-LefttContainer-Img{
    border: 3px solid blue;
    /* padding: 2%; */
    object-fit: contain;
    height: 90% !important;
}

.homeService-RightConatiner-Img img{
    width: 100%;
    height: 100%;
    transform: translateX(-3%) translateY(3%);
}

.homeService-LefttContainer-Img img{
    width: 100%;
    height: 100%;
    transform: translateX(3%) translateY(3%) !important;
}

@media screen and (max-width:768px) {
    .homeServiceBody{
        flex-direction: column;
        justify-content: space-around;
        /* border: 1px solid red; */
    }

    .homeServiceBody-row1{
        flex-direction: column-reverse;
    }
    
    .homeServiceBody-row2{
        flex-direction: column;
    }

    .homeService-LeftConatiner{
        width: 100%;
    }
    
    .homeService-RightConatiner{
        width: 100%;
        padding: 1%;
    }
    
    .services-btn{
        margin-left: 10px;
    }
    
    .homeService-RightConatiner-Img, .homeService-LefttContainer-Img{
       margin: 5px 0px 5px 0px;
       border: 0px;
       margin-top: 20px;
       margin-bottom: 20px;
    }

    /* .homeService-LefttContainer-Img */
    
    .homeService-RightConatiner-Img img{
        transform: translateX(0%) translateY(0%);
    }    

    .homeService-LefttContainer-Img img{
        transform: translateX(0%) translateY(0%) !important;
    }    
}