.IndustriesWeServe-Body{
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: url('../../Images/AboutUs/IndustriesWeServeBG.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* border: 1px solid red; */
}

.IndustriesWeServe-Heading{
    text-align: center;
    padding: 5% 0 5% 0;
    color: white;
    /* border: 1px solid red; */
}

.IndustriesWeServe-Heading h1{
    font-size: 40px;
    letter-spacing: 3px;
}

.IndustriesCard-Container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.IndustriesCard{
    height: 200px;
    width: 270px;
    /* border: 1px solid blue; */
    border-radius: 10px;
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: url('../../Images/Services/ServiceCardBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    background: #0000006a;
    color: white;
    position: relative;
    overflow: hidden;
    border: 1px solid white;
    border: 1px solid gray;
}

.IndustriesCard::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(to left, rgb(41, 41, 218) 50%, transparent 50%);
    background-size: 200% 100%;
    transition: background-position .7s ease;
}

.IndustriesCard:hover::before {
    background-position: 100% 0;
}

@media screen and (max-width:768px) {
    .IndustriesCard-Container{
        width: 100%;
        flex-direction: column !important;
        /* border: 1px solid red; */
    }
}