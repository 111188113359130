.careerDetailsBody{
    /* background: linear-gradient( gray 1%, white 98%); */
    /* border: 1px solid red; */
}

.Isearch-JobDesSection{
    /* margin-top: 120px; */
    /* border: 1px solid red; */
}

.banner{
    background: linear-gradient(rgb(187, 187, 240), white);
    /* color: #ffff; */
    margin-bottom: 60px;
    width: 100% !important;
    /* border: 1px solid red; */
}

.Jobbanner-header{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 30px;
    padding-left: 30px; */
    height: 25vh;
    width: 100%;
    padding-top: 70px;
    /* border: 1px solid red; */
}
.Jobbanner-header h1{
 font-size: 35px;
 font-weight: 400;
 text-align: center;
 color: black;
}

.is-Job-Description{
    box-sizing: border-box;
    line-height: 1.42;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
}
.isApply-button{
    color: #fff;
    height: auto;
    font-size: 17px;
    padding:20px 20px;
    background-color: #00a0df;
    border: none;
    min-width: 215px;
    border-radius: 10px;
    text-decoration: none;
}
.isjobRight{
    /* margin-top: 100px; */
    margin-left: 50px;
}
.ISsticky-job{
    position: sticky;
    top: 160px;
}
.Isjob-description{
    font-size: 16px;
    word-wrap: break-word;
}

ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px;
  }

@media screen and (max-width: 768px) {
    .Isearch-JobDesSection{

    }
}