/* first section of the page ================================ */

.img-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-X: hidden;
  
}
.img-wrapper img{
    width: 100%;
    height: 100%;
    transform-origin: top left;
    transform: skew(10deg);
}

.img-container {
  width: 80%;
  height: 100%;
  transform: skew(-10deg);
  overflow-x: hidden;
  position: relative;
}

.overlay{
  background-color: #e5ecf180;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translateX(-120%);
  transition: all ease-in 1s;
}

.img-container.scrolled  .overlay {
  transform: translateX(120%);
  
}
.img-container.effect  .overlay {
  transform: translateX(100%);
  
}



.img-container::before {
  content: "";
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 13%;
  background-color: transparent !important;
  overflow: none;
  /* border: 1px solid red; */
}
.button {
  width: 200px;
  color: black;
  border: 2px solid #0070c0 !important;
  background-position: 100%;

  transition: all ease-in 0.2s;
  border-radius: 0 !important;
}
.button:hover {
  color: white;

  background-image: -webkit-linear-gradient(
    30deg,
    #2852db 50%,
    transparent 50%
  );
  background-image: linear-gradient(30deg, #2852db 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

.text-container {
  width: 80%;
  /* height: 90vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-container h1 {
  width: 80%;
  /* font-size: 3rem; */
}
.text-container h6 {
  width: 80%;
  /* font-size: 1.2rem; */
}


.content-left {
  margin-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  text-align: left;
}
.content-wrap p {
  font-size: 1.2rem;
}
.content-right {
  text-align: left;
  font-weight: bold;
  height: 50vh;
}
.media {
  padding: 1.5rem;
  display: flex;
}
.media .icon {
  padding-right: 2rem;
}
.media h6 {
  font-size: 1.2rem;
  font-weight: 300;
}
.faq p {
  display: flex;
  align-items: start;
  font-weight: bold;
  font-size: 20px;
}

.heading {
  font-size: 2rem !important;
  font-weight: 700 !important;
  color: black !important;
}

.sub-heading,
.headind h6 {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: black !important;
  text-align: justify;
}


/* first section css ending ================================== */

/*  second section css ends here =============== */

/* .section-border-top{
    border-top: 2px solid black;
} */


/* achievement section ================== */

.social-lp {
  text-align: left;
  color: black !important;
  padding: 1rem;
}
.social-lp h6 {
  font-size: 1rem;
  color: black !important;
  padding: 1rem;
}
.social-lp h4 {
  font-size: 2.2rem;
  padding-bottom: 2rem;
  color: black !important;
  padding-left: 1rem;
}
.stats {
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 1.5rem;
}
.social-rp h3 {
  text-align: left;
  padding-top: 1rem;
  color: black !important;
  font-size: 2.4rem;
  padding: 40px !important;

  font-style: italic;
  text-align: center;
  line-height: 1.3;
  font-weight: 700 !important;
}
.social-rp .who {
  font-size: 2rem;
}
.achievement {
  background-image: url("../../../Images/Services/Social/bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
 
  border-radius: 5px;
}


.text-left{
  font-weight: 200 !important;
}

/* --Images Border ----------*/

.ServicesImagesContainer{
  display: flex;
}

.Services-RightConatiner-Img, .Services-LefttConatiner-Img{
  border: 3px solid blue;
  /* padding: 2%; */
  object-fit: contain;
  height: 90% !important;
  /* width: 400px; */
}

.Services-RightConatiner-Img img{
  width: 100%;
  height: 100%;
  transform: translateX(-3%) translateY(3%);
}

.Services-LefttConatiner-Img img{
  width: 100%;
  height: 100%;
  transform: translateX(3%) translateY(3%) !important;
}

/* media query of all sections ========================== */


@media screen and (max-width: 769px) {
  .first-section-container {
    display: flex;
    justify-content: center;
  }

  .column{
    margin-left: 10px   !important;
    margin-right: 10px !important;
    padding-left: 15px  !important;
    padding-right: 15px !important;
  }

  .Services-RightConatiner-Img, .homeService-LeftConatiner-Img, .Services-LefttConatiner-Img{
    margin: 15px 0px 15px 0px;
    border: 0px;
 }
 
 .Services-RightConatiner-Img img, .Services-LefttConatiner-Img img{
     transform: translateX(0%) translateY(0%);
     border-radius: 15px;
 }    

 .Services-LefttConatiner-Img img{
     transform: translateX(0%) translateY(0%) !important;
 }  
 

 .ServicesImagesContainer{
  flex-direction: column;
}

 .ServicesImagesContainer-odd{
  flex-direction: column-reverse;
  /* border: 1px solid red; */
  } 
}

@media (min-width:768px) and (max-width: 991px) {
   .ServicesImagesContainer{
    /* flex-direction: column-reverse; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  } 

  .Services-RightConatiner-Img, .Services-LefttConatiner-Img{
    height: 350px !important;
    width: 330px;
  }
}